<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="{'modal-container-mobile': !isDesktop}">
          <div class="modal-header bg_darkest">
            <button type="button" class="close" @click="$emit('close')"><span aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <iframe src="https://www.more.co.za/privacy-policy-clean/" :style="{height: '500px'}" style="border: 0; width: 100%" frameborder="0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'TAndC',
  props: {
    isDesktop: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  margin: 0;
  padding: 0;
}

.modal-container {
  width: 60vw;
  margin: 0px auto;
/*   padding: 20px 30px; */
  background-color: #fff;
  border-radius: 0.3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
}

.modal-container-mobile {
  width: 90vw !important;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}
</style>
